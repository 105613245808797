@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

@layer components {
	.icon-btn {
		@apply flex items-center gap-3 p-2 pr-4 rounded-3xl text-bg2 font-bold text-xs;
	  }
	  
	  .btn {
		@apply mobile3:py-4 mobile3:px-6 px-4  py-3.5 rounded-3xl mobile3:text-xs text-xxs font-bold;
	  }
	  
	  .status {
		@apply flex py-3 w-bar h-10 items-center justify-center gap-2 font-bold text-xs relative;
	  }
	  
	  .checkbox {
		@apply h-4 w-4 bg-gray3 dark:bg-bg1-dark -mt-1 border border-purple-500 rounded-sm flex items-center justify-center;
	  }
	  
	  .checkbox.active {
		@apply bg-purple-500;
	  }
	  
	  .checkbox.active img {
		@apply mb-0.5 mr-0.5 block;
	  }
	  
	  .checkbox img {
		@apply hidden;
	  }
	  
	  .label {
		@apply flex text-gray4 dark:text-gray3 text-xs mb-2 w-full justify-between;
	  }
	  
	  .labelError {
		@apply flex text-red-500 text-xs mb-2 w-full justify-between;
	  }
	  
	  .input {
		@apply block w-full h-12 border border-input dark:border-bg2-dark mobile2:px-5 px-3 rounded mb-6 text-black dark:text-white dark:bg-bg2-dark text-xs font-bold;
	  }
	  
	  .inputError {
		@apply block w-full h-12 border border-red-500 px-5 rounded mb-6 text-black dark:bg-bg2-dark text-xs font-bold;
	  }
	  
	  .input2 {
		@apply block w-full h-12 border border-input dark:border-bg2-dark px-2 rounded mb-2 text-black dark:text-white dark:bg-bg2-dark text-xs font-bold;
	  }
	  
	  .input2Error {
		@apply block w-full h-12 border border-red-500 px-2 rounded mb-2 text-black dark:text-white dark:bg-bg2-dark text-xs font-bold;
	  }
	  
	  .formHeight1 {
		height: calc(100vh - theme("spacing.20"));
	  }
	  
	  .formBody {
		@apply fixed tab:top-0 bottom-0 -left-0 z-50 w-full tab3:w-form1_tab tab:w-form1 tab:h-screen bg-bg2 dark:bg-bg1-dark  tab:pl-bar mobile2:pt-12 pt-6 pb-24;
	  }
	  
	  .overlay {
		@apply fixed top-0 left-0 z-20 w-screen h-screen bg-black;
	  }
	}

	.name{
		display: none !important;
	}